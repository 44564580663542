import React from "react";
import cn from "classnames";

const DropDownMenuButton = ({ disabled, onClick, children }) => {
    return (
        <li className="[ DropDownMenuLink ]" tabIndex={-1}>
            <div
                className={cn(
                    {
                        DropDownMenuLink__link: true
                    },
                    {
                        Font_textSemiBold: true,
                        Color_darkBlue: !disabled,
                        Color_darkBlue48: disabled
                    }
                )}
                onClick={onClick}
            >
                {children}
            </div>
        </li>
    );
};

export { DropDownMenuButton };
