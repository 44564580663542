import { graphql } from "gatsby";
import React from "react";
import smoothScroll from "smoothscroll-polyfill";
import { Footer } from "../Footer";
import { Header } from "../Header";
import "./styles.css";
import { FooterThank } from "../FooterThank";

if (typeof window !== "undefined") {
  smoothScroll.polyfill();
}

const Layout = ({
  pageId,
  path,
  address,
  email,
  phoneNumber,
  logo,
  shortLogo,
  form,
  addressLabel,
  contactsLabel,
  workTimeLabel,
  workTime,
  socialNetworks,
  unp,
  copyright,
  children,
  templateKey,

  thankPage,
}) => {
  return (
    <div className="Layout">
      <Header
        path={path}
        address={address}
        email={email}
        phoneNumber={phoneNumber}
        logo={logo}
        shortLogo={shortLogo}
        templateKey={templateKey}
      />

      <div className="content">{children}</div>

      {!thankPage && (
        <Footer
          pageId={pageId}
          form={form}
          addressLabel={addressLabel}
          address={address}
          contactsLabel={contactsLabel}
          phoneNumber={phoneNumber}
          email={email}
          workTimeLabel={workTimeLabel}
          workTime={workTime}
          socialNetworks={socialNetworks}
          shortLogo={shortLogo}
          unp={unp}
          copyright={copyright}
        />
      )}

      {thankPage && (
        <FooterThank shortLogo={shortLogo} unp={unp} copyright={copyright} />
      )}
    </div>
  );
};

Layout.getLivePreviewData = ({ data, getAsset }) => ({
  layout: {
    address: "Test",
    email: "Test",
    phoneNumber: "213",

    logo: "/img/формула-воды.jpg",
    shortLogo: "/img/h2o.jps",

    form: {
      title: "Связаться с нами",
      text:
        "Оставьте свой телефон и мы перезвоним в течении 5 минут.",
      buttonLabel: "Отправить",
      consentToTheProcessing:
        "Нажимая кнопку “Отправить” вы соглашаетесь на передачу и обработку персональных данных.",
    },

    addressLabel: "Мы находимся:",
    contactsLabel: "Наши контакты:",
    workTimeLabel: "Время работы:",
    workTime: "Каждый день 10:00 – 19:00",
    socialNetworks: [],
    unp: "УНП 591028957",
    copyright: '© ООО "Формула воды"',
  },
});

const layoutQuery = graphql`
  fragment LayoutFragment on MarkdownRemarkFrontmatter {
    address
    email
    phoneNumber

    logo {
      childImageSharp {
        fixed(height: 32, quality: 90) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    shortLogo {
      childImageSharp {
        fixed(height: 32, quality: 90) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }

    form {
      title
      text
      buttonLabel
      consentToTheProcessing
    }

    addressLabel
    address
    contactsLabel
    phoneNumber
    email
    workTimeLabel
    workTime
    socialNetworks {
      icon {
        publicURL
      }
      link
    }
    unp
    copyright
  }
`;

export { Layout, layoutQuery };
