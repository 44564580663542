import React, { useCallback, useEffect, useRef, useState } from "react";
import { useOnClickOutside } from "../../../../hooks/useOnClickOutside";
import { Case } from "../../../../icons/Case";
import { ExpandArrow } from "../../../../icons/ExpandArrow";
import { House } from "../../../../icons/House";
import { Knife } from "../../../../icons/Knife";
import { DropDownMenu, DropDownMenuLink } from "../../../DropDownMenu";
import "./styles.css";

const SolutionsMenu = () => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const handleToggleMenu = useCallback(() => setIsOpenMenu(!isOpenMenu), [
    isOpenMenu,
  ]);
  const handleCloseMenu = useCallback(() => setIsOpenMenu(false), []);

  const menu = useRef(null);
  useOnClickOutside(menu, handleCloseMenu);

  useEffect(() => {
    window.addEventListener("scroll", handleCloseMenu, { passive: true });
  });

  return (
    <li className="SolutionsMenu" ref={menu}>
      <button
        className="[ SolutionsMenu__button ] [ Color_darkBlue80 Font_smallText ]"
        onClick={handleToggleMenu}
      >
        Наши решения
        <ExpandArrow />
      </button>

      <DropDownMenu
        className="SolutionsMenu__menu"
        isOpen={isOpenMenu}
        onClose={handleCloseMenu}
      >
        <DropDownMenuLink href="/house/#house" onClick={handleCloseMenu}>
          <House />
          <div>Для всего дома</div>
        </DropDownMenuLink>
        <DropDownMenuLink href="/house/#kitchen" onClick={handleCloseMenu}>
          <Knife />
          <span>Для кухни</span>
        </DropDownMenuLink>
        <DropDownMenuLink href="/business" onClick={handleCloseMenu}>
          <Case />
          <span>Для бизнеса</span>
        </DropDownMenuLink>
      </DropDownMenu>
    </li>
  );
};

export { SolutionsMenu };
