import { Link } from "gatsby";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Headroom from "react-headroom";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { Case } from "../../icons/Case";
import { House } from "../../icons/House";
import { Knife } from "../../icons/Knife";
import { Menu } from "../../icons/Menu";
import { Phone } from "../../icons/Phone";
import { DropDownMenu, DropDownMenuLink } from "../DropDownMenu";
import { Image } from "../Image";
import { SolutionsMenu } from "./components/SolutionsMenu";
import "./styles.css";

const Header = ({
  path,
  address,
  email,
  phoneNumber,
  logo,
  shortLogo,
  templateKey,
}) => {
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);
  const handleToggleMobileMenu = useCallback(
    () => setIsOpenMobileMenu(!isOpenMobileMenu),
    [isOpenMobileMenu]
  );
  const handleCloseMobileMenu = useCallback(
    () => setIsOpenMobileMenu(false),
    []
  );

  const mobileHeader = useRef(null);
  useOnClickOutside(mobileHeader, handleCloseMobileMenu);

  useEffect(() => {
    window.addEventListener("scroll", handleCloseMobileMenu, { passive: true });
  });

  const links = [
    ["Каталог", "/catalog"],
    ["Анализ воды", "/#analysis"],

    ["Связаться с нами", (path || "") + "/#contact-us"],
    ["Блог", "/blog"],
  ];

  const linkWithoutContactUS = [
    ["Каталог", "/catalog"],
    ["Анализ воды", "/#analysis"],

    ["Блог", "/blog"],
  ];
  return (
    <>
      <div className="[ Header__contacts ] [ Color_white Font_smallestText ]">
        <div className="[ Header__contactsContent ] [ Content md:Content_medium ]">
          <span>{address}</span>
          <div className="Header__contactsContacts">
            <span>{email}</span>
            {phoneNumber.split("\n\n").map((phoneNumberItem, index) => {
              if (index === 0) {
                return (
                  <span className="[ Color_white ]">{phoneNumberItem}</span>
                );
              } else {
                return (
                  <a
                    className="[ Color_white ]"
                    href={`tel:${phoneNumberItem}`}
                  >
                    {phoneNumberItem}
                  </a>
                );
              }
            })}
          </div>
        </div>
      </div>

      <Headroom pinStart={32}>
        <div className="[ Header Header_desktop ] [ Content md:Content_medium ]">
          <Link className="Header__logo" to="/">
            <Image image={logo} />
          </Link>
          <Link className="Header__shortLogo" to="/">
            <Image image={shortLogo} />
          </Link>

          <nav>
            <ul className="Header__navigation">
              <li>
                <Link
                  className="[ Header__navigationLink ] [ Color_darkBlue80 Font_smallText ]"
                  to="/"
                >
                  Главная
                </Link>
              </li>

              <SolutionsMenu />

              {templateKey === "thank-page" &&
                linkWithoutContactUS.map(([title, link]) => (
                  <li key={title}>
                    <Link
                      className="[ Header__navigationLink ] [ Color_darkBlue80 Font_smallText ]"
                      to={link}
                    >
                      {title}
                    </Link>
                  </li>
                ))}

              {templateKey !== "thank-page" &&
                links.map(([title, link]) => (
                  <li key={title}>
                    <Link
                      className="[ Header__navigationLink ] [ Color_darkBlue80 Font_smallText ]"
                      to={link}
                    >
                      {title}
                    </Link>
                  </li>
                ))}
            </ul>
          </nav>
        </div>

        <div
          className="[ Header Header_mobile ] [ Content md:Content_medium ]"
          ref={mobileHeader}
        >
          <button
            className="Header__icon"
            onClick={handleToggleMobileMenu}
            aria-label="Открыть меню"
          >
            <Menu />
          </button>
          <Link className="Header__shortLogo" to="/">
            <Image image={shortLogo} />
          </Link>
          <a
            className="Header__icon"
            href={`tel:${phoneNumber.split("  ")[1]}`}
          >
            <Phone />
          </a>

          <DropDownMenu
            className="Header__mobileMenu"
            isOpen={isOpenMobileMenu}
            onClose={handleCloseMobileMenu}
          >
            <DropDownMenuLink href="" disabled>
              Наши решения
            </DropDownMenuLink>
            <DropDownMenuLink
              href="/house/#house"
              onClick={handleCloseMobileMenu}
            >
              <House />
              <div>Для всего дома</div>
            </DropDownMenuLink>
            <DropDownMenuLink
              href="/house/#kitchen"
              onClick={handleCloseMobileMenu}
            >
              <Knife />
              <span>Для кухни</span>
            </DropDownMenuLink>
            <DropDownMenuLink href="/business" onClick={handleCloseMobileMenu}>
              <Case />
              <span>Для бизнеса</span>
            </DropDownMenuLink>

            <div
              style={{
                width: "100%",
                height: 1,
                background: "#EFF3F6",
                marginTop: 20,
                marginBottom: 20,
              }}
            />

            <DropDownMenuLink href="/" onClick={handleCloseMobileMenu}>
              Главная
            </DropDownMenuLink>

            <DropDownMenuLink href="/catalog" onClick={handleCloseMobileMenu}>
              Каталог
            </DropDownMenuLink>

            <DropDownMenuLink href="/#analysis" onClick={handleCloseMobileMenu}>
              Анализ воды
            </DropDownMenuLink>

            {templateKey !== "thank-page" && (
              <DropDownMenuLink
                href={(path || "") + "#contact-us"}
                onClick={handleCloseMobileMenu}
              >
                Связаться с нами
              </DropDownMenuLink>
            )}
            <DropDownMenuLink href="/blog" onClick={handleCloseMobileMenu}>
              Блог
            </DropDownMenuLink>
          </DropDownMenu>
        </div>
      </Headroom>
    </>
  );
};

export { Header };
