import React from "react";

export const Knife = props => {
  return (
    <svg
      width="24"
      height="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.564 3l-5.6 5.6-.007-.006-.57.57 2.853 2.854c2.183-2.211 4.046-5.305 3.725-8.518l-.4-.5zm-11.746.016a1.99 1.99 0 00-1.414.582L3.576 6.426a2.014 2.014 0 00.002 2.828l.002.002 1.889 1.867a3.014 3.014 0 004.23.01l9.836 9.838 1.414-1.414-9.836-9.836.012-.012a3.014 3.014 0 000-4.242l-.002-.002-1.89-1.87c-.387-.386-.9-.58-1.415-.58zm.002 1.996l.002.002 1.75 1.73c.208.212.207.493-.004.703-.212.212-.495.212-.707 0l-.01.01-1.738-1.738.707-.707zM5.7 7.132l1.792 1.794a.463.463 0 01-.045.642c-.211.212-.495.21-.707 0l-1.748-1.73.707-.705zm3.444 6.274l-6.087 6.088 1.414 1.414 6.088-6.088-1.415-1.414z"
        fill="#194068"
      />
    </svg>
  );
};
