import React, { useEffect, useState } from "react";
import { paragraph } from "../../utils/paragraph";
import { Form } from "../Form";
import { YellowSquareLogo } from "./../YellowSquareLogo/YellowSquareLogo";
import { Image } from "../Image";
import "./styles.css";
import { useHashObserver } from "../../hooks/useHashObserver";

const Footer = ({
  pageId,
  form,
  addressLabel,
  address,
  contactsLabel,
  phoneNumber,
  email,
  workTimeLabel,
  workTime,
  socialNetworks,
  shortLogo,
  unp,
  copyright,
}) => {
  const [mapSrc, setMapSrc] = useState("");

  useEffect(() => {
    window.onload = function() {
      setMapSrc(
        "https://yandex.com/map-widget/v1/?um=constructor%3A1f9b49ec868eacd0bf0681a0e905e5968998d18e18bbe111750afa773ad89b8b&amp;source=constructor&lang=ru_RU"
      );
    };

    setTimeout(
      () =>
        setMapSrc(
          "https://yandex.com/map-widget/v1/?um=constructor%3A1f9b49ec868eacd0bf0681a0e905e5968998d18e18bbe111750afa773ad89b8b&amp;source=constructor&lang=ru_RU"
        ),
      15000
    );
  }, []);

  useHashObserver("#contact-us");

  return (
    <div className="[ Footer ] [ Content md:Content_small ]">
      <div className="Footer__content">
        <Form
          className="Footer__form"
          pageId={pageId}
          formId="Подвал"
          {...form}
        />

        <div className="Footer__info">
          <div className="Footer__map">
            {mapSrc ? (
              <iframe title="Карта" src={mapSrc} frameBorder="0" />
            ) : (
              <div className="Footer__mapLoader" />
            )}
          </div>

          <div className="[ Footer__contacts ] [ Font_smallText Color_darkBlue ]">
            <div className="Footer__contactsGroup">
              <div>
                <div className="Footer__contactLabel [ Font_textSemiBold Color_darkBlue48 ]">
                  {addressLabel}
                </div>
                <div
                  className="Footer__contact"
                  dangerouslySetInnerHTML={{ __html: paragraph(address) }}
                />
              </div>
              <div>
                <div className="Footer__contactLabel [ Font_textSemiBold Color_darkBlue48 ]">
                  {workTimeLabel}
                </div>
                <div
                  className="Footer__contact"
                  dangerouslySetInnerHTML={{ __html: paragraph(workTime) }}
                />
              </div>
            </div>

            <div className="Footer__contactsGroup">
              <div>
                <div className="Footer__contactLabel [ Font_textSemiBold Color_darkBlue48 ]">
                  {contactsLabel}
                </div>
                <div className="Footer__contact">
                  <a
                    className="Color_darkBlue"
                    href={`tel:${phoneNumber}`}
                    dangerouslySetInnerHTML={{ __html: paragraph(phoneNumber) }}
                  />
                </div>
                <div
                  className="Footer__contact"
                  dangerouslySetInnerHTML={{ __html: paragraph(email) }}
                />
              </div>

              <div className="Footer__socialNetworks">
                {socialNetworks.map(({ icon, link }) => (
                  <a
                    key={link}
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={icon.publicURL} alt="" />
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="Footer__logos">
        <div className="[ Footer__formula ] [ Font_smallestText Color_darkBlue80 ]">
          <Image className="Footer__logo" image={shortLogo} />
          <div>
            <span>{unp}</span>
            <br />
            <span>{copyright}</span>
          </div>
        </div>
        <a
          className="[ Font_smallText Color_darkBlue ]"
          href="https://studio-1.by"
          target="_blank"
        >
          <YellowSquareLogo />
        </a>
      </div>
    </div>
  );
};

export { Footer };
