import React, { useEffect, useState } from "react";
import { Image } from "../Image";
import { YellowSquareLogo } from "./../YellowSquareLogo/YellowSquareLogo";
import "./styles.css";

const FooterThank = ({ shortLogo, unp, copyright }) => {
  return (
    <div id="contact-us" className="[ FooterThank ]">
      <div className="  [ Content md:Content_small ]">
        <div className="Footer__logos">
          <div className="[ Footer__formula ] [ Font_smallestText Color_darkBlue80 ]">
            <Image className="Footer__logo" image={shortLogo} />
            <div>
              <span>{unp}</span>
              <br />
              <span>{copyright}</span>
            </div>
          </div>
          <a
            className="[ Font_smallText Color_darkBlue ]"
            href="https://studio-1.by"
            target="_blank"
          >
            <YellowSquareLogo />
          </a>
        </div>
      </div>
    </div>
  );
};

export { FooterThank };
