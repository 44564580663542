import React from "react";

const House = props => {
  return (
    <svg
      width="24"
      height="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 3L2 9h8L6 3zm2.152 0l3.883 6h9.803l-3.883-6H8.152zM2 11v8c0 1.103.897 2 2 2h6V11H2zm10 0v10h8c1.103 0 2-.897 2-2v-8H12zm3 3h4v4h-4v-4z"
        fill="#194068"
      />
    </svg>
  );
};

export { House };
