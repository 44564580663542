import { useEffect } from "react";

export const useHashObserver = (hash) => {
  useEffect(() => {
    if (window.location.hash === hash) {
      const target = document.getElementById(hash.substr(1));
      if (target) target.scrollIntoView({ block: "center", inline: "nearest" });
    }
  }, [hash]);
};
