import cn from "classnames";
import React, { useCallback, useRef, useState, useEffect } from "react";
import NumberFormat from "react-number-format";
import { Button } from "../Button";
import { Spinner } from "../Spinner";
import { navigate } from "@reach/router";
import "./styles.css";
// import { useHashObserver } from "../../hooks/useHashObserver";

const Form = ({
  pageId,
  colorTheme = "blue",
  formId,
  id,
  className,
  subtitle,
  title,
  text,
  buttonLabel,
  consentToTheProcessing,
}) => {
  const [name, setName] = useState("");
  const handleChangeName = useCallback((e) => setName(e.target.value), []);

  // const targetRef = useHashObserver("#contact-us");

  const phoneNumberInput = useRef(null);
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  const [{ isLoading, done, error }, setQueryState] = useState({
    isLoading: false,
    done: false,
    error: false,
  });

  const resetForm = useCallback(() => {
    setName("");
    if (phoneNumberInput.current) {
      phoneNumberInput.current.value = "";
    }
    setQueryState({ isLoading: false, done: false, error: false });
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      let phoneNumber = "";
      if (phoneNumberInput.current && phoneNumberInput.current.value) {
        phoneNumber = phoneNumberInput.current.value;
      }

      if (phoneNumber.indexOf("_") >= 0 || !phoneNumber) {
        return setPhoneNumberError(true);
      } else {
        setPhoneNumberError(false);
      }

      setQueryState({ isLoading: true, done: false, error: false });

      const response = await fetch("/api/form", {
        method: "POST",
        body: JSON.stringify({
          Имя: name,
          "Номер телефона": phoneNumber,
          Страница: pageId,
          Форма: formId,
        }),
        headers: {
          "content-type": "application/json",
        },
      });

      if (!response.ok) {
        if (typeof ym === "function") {
          ym(56280724, "reachGoal", "form");
        }
        return setQueryState({ isLoading: false, done: true, error: true });
      } else {
        return setQueryState({ isLoading: false, done: true, error: false });
      }
      //navigate("/thank");
    },
    [pageId, formId, name]
  );

  const [isActivePhoneNumberInput, setIsActivePhoneNumberInput] = useState("");

  const formTheme = cn({
    Form__blue: colorTheme === "blue",
    Color_white: colorTheme === "blue",

    Form__white: colorTheme === "white",
    Color_darkBlue: colorTheme === "white",
  });

  console.log(formId);

  const formfieldsTheme = cn({});

  return (
    <form
      id={id}
      className={cn(className, `[ Form ] [ ${formTheme} ]`)}
      onSubmit={handleSubmit}
    >
      {subtitle && (
        <div className="[ Form__question ] [ Font_bigText ]">{subtitle}</div>
      )}

      <legend className=" [ Form__title ] [ Font_headerMiddle ]">
        {title || "Хотите оставить заявку"}
      </legend>
      <div className="[ Form_text ] [ Font_textRegular ]">
        {text ||
          "Оставьте нам свой номер, и мы перезвоним вам в кратчайшие сроки "}
      </div>

      {!done && (
        <>
          <div
            className="Form__fields"
            id={
              formId === "Подвал" ? "contact-us" : "contact-us-howWeAreWorking"
            }
          >
            {/* <input
              type="text"
              name="name"
              placeholder="Имя"
              required
              autoComplete="off"
              value={name}
              onChange={handleChangeName}
            /> */}
            <NumberFormat
              getInputRef={(el) => (phoneNumberInput.current = el)}
              format="+375 (##) ###-##-##"
              placeholder="Номер телефона"
              mask="_"
              required
              allowEmptyFormatting={isActivePhoneNumberInput}
              onFocus={() => setIsActivePhoneNumberInput(true)}
              onBlur={() => setIsActivePhoneNumberInput(false)}
            />

            {phoneNumberError && <p>Проверьте введенный номер телефона</p>}
          </div>
          <Button
            variant={!isLoading ? "outlined" : ""}
            className="Form__button"
          >
            {!isLoading ? buttonLabel || "Оставить заявку" : <Spinner />}
          </Button>
          <div className="Form__policy">
            {consentToTheProcessing ||
              "Нажимая кнопку “Отправить” вы соглашаетесь на передачу и обработку персональных данных."}
          </div>
        </>
      )}

      {done && !error && (
        <div className="Form__feedback">
          <p className="[ Font_headerSmall ]">
            Спасибо, мы свяжемся с вами в ближайшее время
          </p>
          <Button
            color="grey"
            onClick={resetForm}
            variant="outlined"
            type="button"
          >
            Отправить ещё одну заявку
          </Button>
        </div>
      )}

      {done && error && (
        <div className="Form__feedback">
          <p className="[ Font_headerSmall ]">
            У нас не получилось отправить вашу заявку. Можете позвонить по
            номеру или попробовать ещё раз.
          </p>
          <Button onClick={resetForm} variant="outlined" type="button">
            Попробовать ещё раз
          </Button>
        </div>
      )}
    </form>
  );
};

export { Form };
