import React from "react";
import "./styles.css";
import cn from "classnames";

export function Button({
  className,
  variant = "normal",
  children,
  rightIcon,
  color = "blue",
  ...props
}) {
  return (
    <button
      className={cn(
        {
          Button_icon: !!rightIcon,
        },
        `[ Button Button_${variant}  Button_${color} ]  [ Font_textSemiBold ]`,
        className
      )}
      {...props}
    >
      {children}
      {rightIcon && <div className="Button__icon">{rightIcon}</div>}
    </button>
  );
}
