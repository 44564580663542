import React from "react";
import { Link } from "gatsby";
import cn from "classnames";

const DropDownMenuLink = ({ href, disabled, onClick, children }) => {
  return (
    <li className="[ DropDownMenuLink ]" tabIndex={-1}>
      <Link
        className={cn(
          {
            DropDownMenuLink__link: true
          },
          {
            Font_textSemiBold: true,
            Color_darkBlue: !disabled,
            Color_darkBlue48: disabled
          }
        )}
        to={href}
        role="menuitem"
        disabled={disabled}
        onClick={onClick}
      >
        {children}
      </Link>
    </li>
  );
};

export { DropDownMenuLink };
