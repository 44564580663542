import React from "react";

import "./styles.css";

const Spinner = props => (
  <svg
    className="Spinner"
    viewBox="25 25 50 50"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      className="path"
      cx="50"
      cy="50"
      r="20"
      fill="none"
      strokeWidth="2"
      strokeMiterlimit="10"
      stroke="white"
    />
  </svg>
);

export { Spinner };
