import React from "react";

const Case = props => {
  return (
    <svg
      width="24"
      height="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 3c-.522 0-1.055.191-1.432.568C8.191 3.945 8 4.478 8 5v1H5C3.355 6 2 7.355 2 9v10c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V9c0-1.645-1.355-3-3-3h-3V5c0-.522-.191-1.055-.568-1.432C15.055 3.191 14.522 3 14 3h-4zm0 2h4v1h-4V5zM5 8h14c.555 0 1 .445 1 1v5c0 .555-.445 1-1 1H5c-.555 0-1-.445-1-1V9c0-.555.445-1 1-1zm7 4a1 1 0 100 2 1 1 0 000-2z"
        fill="#194068"
      />
    </svg>
  );
};

export { Case };
